document.addEventListener('DOMContentLoaded', () => {
  const form = document.querySelector('#whois-form');
  if (form) {
    const result = document.querySelector('#whois-result');
    form.addEventListener('ajax:before', function (event) {
      result.innerHTML = '<div class="tc">Please wait...</div>';
    });
    form.addEventListener('ajax:success', function (event) {
      const ref = event.detail;
      const xhr = ref[2];
      result.innerHTML = xhr.response;
    });
    form.addEventListener('ajax:error', function (event) {
      const ref = event.detail;
      const xhr = ref[2];

      result.innerHTML = `<div class="tc">${xhr.response}</div>`;
    });
  }
});
