class ToggleElement {
  initialize () {
    const items = document.querySelectorAll('[data-toggle-target]');
    for (const item of items)
      this.listenToClicks(item);
  }

  listenToClicks (item) {
    item.addEventListener('click', event => {
      this.toggle(item);
    });
  }

  toggle (item) {
    const toggles = item.parentNode.querySelectorAll('[data-toggle-element]');

    for (const toggle of toggles) {
      const target = item.getAttribute('data-toggle-target').substring(1); // removes # or . in the target name
      if (toggle.id === target)
        toggle.classList.remove('is-hidden');
      else
        toggle.classList.add('is-hidden');
    }
  }

  static ready () {
    const toggleElement = new ToggleElement();
    toggleElement.initialize();
  }
};

export default ToggleElement;
