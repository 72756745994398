import { createApp, defineAsyncComponent } from 'vue/dist/vue.esm-bundler.js';

import '../javascript/legacy/app/contact_form.js';
import '../javascript/legacy/app/whois_form.js';
import '../javascript/analytics/posthog.js';
import Rails from '@rails/ujs';
import initBugsnag from '../javascript/common/bugsnag.js';
import KeepInView from '../javascript/common/keep-in-view.js';
import TextCycle from '../javascript/legacy/app/text-cycle.js';
import ToggleElement from '../javascript/legacy/app/toggle_element.js';
import Tab from '../components/tab/component.vue'; // cannot be loaded async at the moment.
import Signup from '../javascript/legacy/app/signup.js';

const EventTrigger = defineAsyncComponent(() => import('../components/event-trigger/component.vue'));
const ContactSalesModal = defineAsyncComponent(() => import('../components/contact-sales-modal/component.vue'));
const ConnectorSearch = defineAsyncComponent(() => import('../components/connector-search/component.vue'));
const PricingCalculator = defineAsyncComponent(() => import('../components/pricing-calculator/component.vue'));

const ApiBrowser = defineAsyncComponent(() => import('../components/api-browser/component.vue'));
const ApiSimulator = defineAsyncComponent(() => import('../components/api-simulator/component.vue'));
const ApiTabs = defineAsyncComponent(() => import('../components/api-tabs/component.vue'));
const Autocomplete = defineAsyncComponent(() => import('../components/autocomplete/component.vue'));
const AutocompleteTld = defineAsyncComponent(() => import('../components/autocomplete-tld/component.vue'));
const AvoidDnsDowntimeChecklist = defineAsyncComponent(() =>
  import('../components/avoid-dns-downtime-checklist/component.vue')
);
const Confetti = defineAsyncComponent(() => import('../components/confetti/component.vue'));
const Tabs = defineAsyncComponent(() => import('../components/tabs/component.vue'));
const Youtube = defineAsyncComponent(() => import('../components/youtube/component.vue'));
const ZoneImportSimulator = defineAsyncComponent(() => import('../components/zone-import-simulator/component.vue'));

const components = {
  'api-browser': ApiBrowser,
  'api-simulator': ApiSimulator,
  'api-tabs': ApiTabs,
  autocomplete: Autocomplete,
  'autocomplete-tld': AutocompleteTld,
  'avoid-dns-downtime-checklist': AvoidDnsDowntimeChecklist,
  confetti: Confetti,
  tab: Tab,
  tabs: Tabs,
  youtube: Youtube,
  connectorSearch: ConnectorSearch,
  'zone-import-simulator': ZoneImportSimulator,
  'contact-sales-modal': ContactSalesModal,
  'event-trigger': EventTrigger,
  pricingCalculator: PricingCalculator
};

const app = createApp({ components });
app.config.compilerOptions.whitespace = 'preserve';

app.mount('.container-main');

document.addEventListener('DOMContentLoaded', () => {
  new KeepInView([...document.querySelectorAll('a.cta:not(.ignore)')]).init();

  TextCycle.ready();
  Signup.ready();
  ToggleElement.ready();

  initBugsnag();

  window.Rails = Rails;

  Rails.start();
});
