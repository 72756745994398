class TextCycle {
  constructor ($el) {
    this.$el = $el;
    this.words = this.$el.dataset.words.split(',');
    this.speed = this.$el.dataset.speed;
    this.index = 0;
    this.cycle();
  };

  cycle () {
    this.$el.innerText = this.words[this.index];
    this.index++;
    if (this.index > this.words.length - 1)
      this.index = 0;

    setTimeout(() => this.cycle(), this.speed);
  };

  static ready () {
    const $els = document.querySelectorAll('.js-text-cycle');

    [...$els].forEach($el => new TextCycle($el));
  }
};

export default TextCycle;
